import React, { useState } from 'react'
import About from './About'
import Contact from './Contact'
import Footer from './Footer'
import Header from './Header'
import Portfolio from './pages/Portfolio'

function App() {
  return (
    <>
      <Header />
      <main>
        <div className="container">
          {/* <About /> */}
          <section
            id="works"
            className="bg-base-200 p-4 sm:p-8 rounded-xl my-10"
          >
            <h4 className="text-4xl text-center py-10">
              <strong>My Works</strong>
            </h4>
            <Portfolio />
            <div className="flex my-10 justify-center">
              <a className="btn btn-outline" href="/works">
                View All →
              </a>
            </div>
          </section>
          {/* <Contact /> */}
        </div>
      </main>
      <Footer />
    </>
  )
}

export default App
