import { MDBCard } from 'mdb-react-ui-kit'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { randomColors } from './shared/utils'
const StyledDiv = styled.div`
  background-color: #593eb6cc;
  color: white;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #2d3194ee;
    p {
      display: block !important;
    }
  }
`
const PortfolioItem = ({ item }) => {
  return (
    <>
      <div className="card w-full h-full xl:flex-row bg-base-100 shadow-xl">
        <figure className="p-4 md:p-8 xl:w-3/5">
          <img
            src={item.coverPhoto.url}
            alt="Shoes"
            className="rounded-xl aspect-square object-cover"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">{item.title}</h2>
          <p>{item.description}</p>
          <div className="flex gap-2 flex-wrap">
            {item.category.map((cat, i) => (
              <div
                key={i}
                className={`badge ${randomColors[i % randomColors.length]}`}
              >
                {cat.name}
              </div>
            ))}
          </div>
          <div className="card-actions mt-2">
            <Link className="btn" to={`/works/${item.slug}`}>
              Read More
            </Link>
          </div>
        </div>
      </div>
      {/*   <MDBCard
        className="overflow-hidden h-100"
        data-mdb-ripple-init
        data-mdb-ripple-color="light"
      >
        <img
          className="img-fluid h-100"
          src={item.coverPhoto.url}
          alt=""
          style={{ objectFit: 'cover', aspectRatio: 1 }}
        />
        <StyledDiv className="position-absolute top-0 bottom-0 end-0 start-0 w-100 d-grid align-items-center align-content-center p-4">
          <h4 className="text-white">{item.title}</h4>
          <p className="text-white opacity-70 d-none">{item.description}</p>
        </StyledDiv>
      </MDBCard> */}
    </>
  )
}

export default PortfolioItem
