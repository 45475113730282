import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import * as cheerio from 'cheerio'
import { QUERY_AUTHOR, QUERY_WORK, graphcms } from '../Graphql/Queries'
import Nav from '../Nav'
import Footer from '../Footer'
import Navbar from '../components/Navbar'
import { randomColors } from '../shared/utils'

const Work = () => {
  const [work, setWork] = useState(null)
  const [authorData, setAuthorData] = useState(null)
  const [h2Tags, setH2Tags] = useState([])
  const { slug } = useParams()

  useEffect(() => {
    fetchWork()
    fetchAuthor()

    if (work) {
      const $ = cheerio.load(work.details.html)
      const extractedH2Tags = $('h2')
        .map((index, element) => ({
          id: `section-${index}`,
          text: $(element).text(),
        }))
        .get()
      console.log($)
      setH2Tags(extractedH2Tags)
    }
  }, [])

  const fetchWork = async () => {
    await graphcms.request(QUERY_WORK, { slug }).then((res) => {
      setWork(res.work)
    })
  }
  const fetchAuthor = async () => {
    await graphcms.request(QUERY_AUTHOR).then((res) => {
      setAuthorData(res.authors[0])
    })
  }
  return (
    <>
      {work && (
        <>
          <Navbar />
          <div className="bg-base-200">
            <div className="grid grid-cols-4 gap-8 items-center container py-20">
              <div className="col-span-full md:col-span-2">
                <div className="text-sm breadcrumbs mb-5">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/works">My works</a>
                    </li>
                    <li>{work.title}</li>
                  </ul>
                </div>
                <h1 className="text-5xl font-bold">{work.title}</h1>
                <p className="py-6">{work.description}</p>
                <div className="flex gap-2 flex-wrap">
                  {work.category.map((cat, i) => (
                    <div
                      key={i}
                      className={`badge ${randomColors[i % randomColors.length]}`}
                    >
                      {cat.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-full md:col-span-2">
                <img
                  src={work.coverPhoto.url}
                  className="rounded-lg aspect-video object-cover"
                />
              </div>
            </div>
          </div>
          <div className="container grid grid-cols-4 gap-8 my-12">
            <div className="col-span-4 bg-white sm:rounded-xl sm:border sm:border-gray-200 md:col-span-3">
              <article
                data-mdx-container="true"
                className="prose prose-gray max-w-none transition-all prose-headings:relative prose-headings:scroll-mt-20 prose-headings:font-display prose-headings:font-bold sm:p-8"
                dangerouslySetInnerHTML={{ __html: work.details.html }}
              ></article>
            </div>
            <div className="sticky top-20 col-span-4 md:col-span-1 flex-col self-start sm:flex">
              <div className="flex flex-col space-y-4 py-5">
                <p className="text-sm text-gray-500">Written by</p>
                {authorData && (
                  <div className="flex items-center gap-4">
                    <img
                      alt={authorData.name}
                      loading="lazy"
                      width="40"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      className="rounded-full transition-all group-hover:brightness-90 blur-0 aspect-square object-cover"
                      src={authorData.picture.url}
                    />
                    <p className="font-semibold text-gray-700">
                      {authorData.name}
                    </p>
                  </div>
                )}
              </div>
              <div className="sm:grid gap-4 border-l-2 border-gray-200 hidden">
                {h2Tags.map((tag) => {
                  return (
                    <a
                      key={tag.id}
                      href={`#${tag.id}`}
                      className={`-ml-0.5 pl-4 text-sm text-gray-500 ${1 == 1 ? 'border-l-2 border-black text-black' : ''}`}
                    >
                      {tag.text}
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
          <Footer/>
        </>
      )}
    </>
  )
}

export default Work
