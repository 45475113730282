import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import { MDBBtn } from 'mdb-react-ui-kit'
import Navbar from './components/Navbar'
import { QUERY_AUTHOR, graphcms } from './Graphql/Queries'

const Header = () => {
  useEffect(() => {
    fetchAuthor()
  }, [])
  const [authorData, setAuthorData] = useState(null)
  const fetchAuthor = async () => {
    await graphcms.request(QUERY_AUTHOR).then((res) => {
      setAuthorData(res.authors[0])
    })
  }
  return (
    <header>
      {/* <Nav /> */}
      <Navbar />
      <div className="hero min-h-screen bg-base-200">
        {authorData && (
          <div className="container flex flex-col items-center gap-8 lg:flex-row-reverse">
            <img
              src={authorData.coverPhoto.url}
              className="md:max-w-xl rounded-lg shadow-2xl aspect-square object-cover"
            />
            <div>
              <p className="text-3xl mb-2">
                Hi! I&apos;m&nbsp;
                <span className="text-primary">{authorData.name}</span>
              </p>
              <h1 className="text-4xl sm:text-6xl font-bold leading-tight text-secondary">
                {authorData.intro}
              </h1>
              <p className="py-6">{authorData.bio}</p>
              <div className="flex justify-start items-center gap-4">
                <a
                  className="btn btn-secondary"
                  href="mailto:amrutha.aneesh.me@gmail.com?subject=Hello"
                >
                  Contact Me
                </a>
                <a className="btn btn-outline" href="#works">
                  See my works ↓
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {/*   <div id="intro" className="bg-image vh-100 shadow-1-strong">
        <img
          className="h-100 w-100 object-cover"
          src="https://images.unsplash.com/photo-1644088379091-d574269d422f?q=80&w=3793&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
        />
        <div
          className="mask"
          style={{
            // background: "rgb(2, 0, 36,.5)",
            backgroundImage:
              'linear-gradient(90deg, rgba(172, 11, 194, 0.5) 0%, rgba(70, 28, 187, 0.5) 35%, rgba(0, 212, 255, 0.5) 100%)',
          }}
        >
          <div className="container d-flex align-items-center justify-content-center text-center h-100">
            <div className="text-white" data-mdb-theme="dark">
              <h1 className="mb-3">Hi! I&apos;m Amrutha Aneesh</h1>
              <h5 className="mb-4">Welcome to my portfolio!</h5>
              <MDBBtn
                href="#works"
                outline
                rounded
                className="me-2"
                color="light"
              >
                See My Works
              </MDBBtn>
              <MDBBtn
                href="#contact"
                rounded
                className="text-dark"
                color="light"
              >
                Get in Touch
              </MDBBtn>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  )
}

export default Header
