import React, { useEffect, useState } from 'react'
import PortfolioItem from '../PortfolioItem'
import { Link } from 'react-router-dom'

import { QUERY_SLUG, graphcms } from '../Graphql/Queries'

const Portfolio = () => {
  const [categories, setCategories] = useState(['all'])
  const [works, setWorks] = useState([])
  const [activeTab, setActiveTab] = useState('all')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await graphcms.request(QUERY_SLUG).then((res) => {
      setActiveTab(res.categories[0].slug)
      setWorks(res.works)
      setCategories(res.categories)
    })
  }

  const handleTabClick = (value) => {
    if (value === activeTab) {
      return
    }
    setActiveTab(value)
  }

  return (
    <>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-8">
        {works.map((work, index) => (
          <PortfolioItem item={work} key={index} />
        ))}
      </div>

      {/*    <div role="tablist" className="tabs tabs-boxed tabs-lg">
        {categories.slice(0, 3).map((category) => (
          <>
            <a
              onClick={() => handleTabClick(category.slug)}
              role="tab"
              className={`tab ${activeTab === category.slug ? 'tab-active' : ''}`}
              key={category.slug}
            >
              {category.name}
            </a>{' '}
            <div role="tabpanel" className="tab-content p-10">
              <div className="grid md:grid-cols-2 gap-8">
                {works
                  .filter((work) =>
                    work.category.some((cat) => cat.name === category.name),
                  )
                  .map((work, index) => (
                    <PortfolioItem item={work} key={index} />
                  ))}
              </div>
            </div>
          </>
        ))}
      </div> */}
    </>
  )
}

export default Portfolio
