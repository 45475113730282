import React from 'react'
import Portfolio from './Portfolio'
import Contact from '../Contact'
import Navbar from '../components/Navbar'
import Footer from '../Footer'

const Works = () => {
  return (
    <>
      <Navbar />
      <section className="bg-base-200 p-8">
        <div className="bg-base-200 mb-12">
          <div className="grid grid-cols-4 gap-8 container py-20">
            <div className="col-span-full sm:col-span-2">
              <div className="text-sm breadcrumbs mb-5">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>My works</li>
                </ul>
              </div>
              <h1 className="text-5xl font-bold">My works</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <Portfolio />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Works
