import { GraphQLClient, gql } from 'graphql-request'

export const graphcms = new GraphQLClient(process.env.REACT_APP_ENDPOINT)

export const QUERY_SLUG = gql`
  {
    categories {
      name
      slug
    }
    works {
      category {
        ... on Category {
          name
        }
      }
      title
      slug
      coverPhoto {
        url
      }
      description
    }
  }
`

export const QUERY_AUTHOR = gql`
  {
    authors {
      name
      intro
      bio
      id
      picture {
        url
      }
      coverPhoto {
        url
      }
    }
  }
`

export const QUERY_WORK = gql`
  query GetWorkBySlug($slug: String!) {
    work(where: { slug: $slug }) {
      id
      title
      coverPhoto {
        url
      }
      category {
        ... on Category {
          name
        }
      }
      description
      details {
        html
      }
    }
  }
`
